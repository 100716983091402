import type { RouteObject } from 'react-router';

import routes from '@/assets/constants/routes-no';
import { competitionWinnersQuery } from '@/lib/api/queries';
import { simpleRace, smartLazy } from '@/lib/router-utils';
import { queryClient } from '@/queryClient';

export const januaryOpen2025Routes: RouteObject[] = [
  {
    path: routes.JANUARY_OPEN_2025_COMPETITION,
    loader: () =>
      simpleRace([queryClient.prefetchQuery(competitionWinnersQuery())]),
    lazy: () => smartLazy(import('./LandingPage/LandingPage')),
  },
  {
    path: routes.JANUARY_OPEN_2025_COMPETITION_TERMS,
    lazy: () => smartLazy(import('./Terms')),
  },
];
